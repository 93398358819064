.root {
}

.boxSx {
  display: none;
}

@screen carouselMd {
  .boxSx {
    display: block;
    width: 100px;
    position: absolute;
    top: 0;
    left: -62px;
    height: 100%;
  }
}

.boxDx {
  display: none;
}

@screen carouselMd {
  .boxDx {
    display: block;
    width: 100px;
    position: absolute;
    top: 0;
    right: -62px;
    height: 100%;
    @apply text-right;
  }
}

.slide {
  @apply px-1 py-2;
}

@screen carouselMd {
  .slide {
    @apply pr-16;
  }
}

.arrow {
  font-size: 46px;
  line-height: 80px;
  @apply h-full mx-2 outline-none cursor-pointer;
}

.arrowEnd {
  display: none !important;
}

.arrowStart {
  display: none !important;
}

.carousel {
}

.carousel :global(.swiper-wrapper) {
  @apply pl-2;
}
