.root {
  position: relative;
  height: 100%;
}

.root :global(.swiper-button-prev:after) {
  font-size: 18px;
  color: #7e7e7e;
}

.root :global(.swiper-button-next:after) {
  font-size: 18px;
  color: #7e7e7e;
}

.withDots {
  @apply mb-4;
}

.arrowsHiddenXs {
}
@media (max-width: theme("screens.sm")) {
  .arrowsHiddenXs :global(.swiper-button-prev) {
    display: none;
  }
  .arrowsHiddenXs :global(.swiper-button-next:after) {
    display: none;
  }
}

.dotsHiddenSm {
}
@media (max-width: theme("screens.sm")) {
  .dotsHiddenSm {
    display: none !important;
  }
}

.slideContainer {
  height: 100%;
}

.slideContainer:focus {
  outline: none;
}

.bulletsTop {
  position: absolute;
  top: 8px;
  left: 0;
  width: 100%;
  z-index: 1;
}
.bulletsBottom {
  @apply pt-8;
}
.bulletsContainer {
  display: flex;
}
.bulletsCenter {
  justify-content: center;
  text-align: center;
}
.bulletsLeft {
  justify-content: flex-start;
  text-align: left;
}
.bulletsRight {
  justify-content: flex-end;
  text-align: right;
}
.bullet {
  margin: 0 5px;
  width: 35px;
  height: 3px;
  cursor: pointer;
  border-radius: 10px;
}
.activeDotFilled {
}
.activeDotFilled
  :global(.swiper-pagination-bullet.swiper-pagination-bullet-active) {
  background-color: theme("colors.primary");
  border: none;
}
.dotsSmall {
}
.dotsSmall :global(.swiper-pagination-bullet) {
  width: 6px;
  height: 6px;
  margin-left: 3px;
  margin-right: 3px;
}
.dotsMedium {
}
.dotsMedium :global(.swiper-pagination-bullet) {
  width: 10px;
  height: 10px;
}
.hAuto :global(.swiper-wrapper) {
  height: auto !important;
}
